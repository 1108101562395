<template>
    <div class="desp">
        <Top name="账号说明" back></Top>
        <div class="desp_w">
            <div class="rule_item">
                <h2>账号说明</h2>
                <div class="rule_item_c">
                    <p>用手机号注册盒子账号后，此盒子账号自动绑定仙境旗下绑定该手机号的所有游戏账号。可对绑定的账号进行角色交易、封号申诉、丢失物品申请找回等相关操作。</p>
                    <p>注：绑定无账号数量上限。</p>
                    <p>例：盒子账号&，与仙境传奇账号A、火龙账号B、时光倒流账号C绑定同一个手机号，则注册后成自动绑定关系。</p>
                    <img src="../assets/images/accountdesp.jpg" alt="">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
 import Top from '@/components/top.vue'

export default {
    name: 'Accountdesp',
    components: {
        Top,
    },
    data() {
        return {

        }
    },
}
</script>
<style scoped>
.desp_w{
    text-align: left;
}
.rule_item{
    padding: .4375rem 0;
    font-size: .8125rem;
    line-height: 1.375rem;
    color: #4c4c4c;
    margin-bottom: .4375rem;
}
.rule_item h2{
    line-height: 1.8125rem;
    color: #FF4E00;
    position: relative;
    padding: 0 .84375rem 0 1.40625rem;
    border-bottom: 1px dashed #b3b3b3;
}
.rule_item h2::before{
    content: '';
    width: 0;
    height: 0;
    border: 4px solid transparent;
    border-left-color: #EF4034;
    position: absolute;
    left: .84375rem;
    top: 50%;
    transform: translate(0, -50%);
}
.rule_item .rule_item_c{
    padding: .9375rem .84375rem;
}
.rule_item .rule_item_c p{
    line-height: 2em;
}
.rule_item .rule_item_c p:nth-child(2){
    color: #EF4034;
}
.rule_item_c img{
    width: 95%;
    display: block;
    margin: 1.1875rem auto;
}
</style>